/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.6
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */
$font-size: 16px;

// Bootstrap
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
// Variables
@import "app/common/variables";
// Layout
@import "app/layout/layout";
@import "app/layout/layout-extra";
@import "app/layout/layout-animation";
@import "app/layout/top-navbar";
@import "app/layout/sidebar";
@import "app/layout/offsidebar";
@import "app/layout/user-block";
@import "app/layout/settings";
// Common
@import "app/common/page-loader";
@import "app/common/typo";
@import "app/common/bootstrap-reset";
@import "app/common/bootstrap-custom";
@import "app/common/button-extra";
@import "app/common/placeholder";
@import "app/common/cards";
@import "app/common/circles";
@import "app/common/dropdown-extra";
@import "app/common/half-float";
@import "app/common/animate";
@import "app/common/slim-scroll";
@import "app/common/inputs";
@import "app/common/utils";
@import "app/common/print";
// Elements
@import "app/elements/nestable";
@import "app/elements/notify";
@import "app/elements/spinner";
// Charts
@import "app/charts/radial-bar";
@import "app/charts/chart-flot";
@import "app/charts/chart-easypie";
// Form elements
@import "app/forms/form-validation";
@import "app/forms/form-tags";
@import "app/forms/form-datepicker";
@import "app/forms/form-imgcrop";
@import "app/forms/dropzone";
@import "app/forms/plugins";
// Tables
@import "app/tables/datatable";
@import "app/tables/table-extras";
// Maps
@import "app/maps/gmap";
@import "app/maps/vector-map";
// Extras
@import "app/extras/timeline";
@import "app/extras/todo";
@import "app/extras/calendar";
@import "app/extras/mailbox";
@import "app/extras/plans";
@import '~react-widgets/lib/scss/react-widgets';

html {
  font-size: 16px;
}

.invalid-feedback-custom {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.btn-color {
  background-color: #061956;
  border-color: #061956;
}

.digit-group {
  input {
    width: 30px;
    height: 50px;
    //background-color: lighten(#0f0f1a, 5%);
    //border: none;
    line-height: 50px;
    text-align: center;
    font-size: 24px;
    font-family: 'Raleway', sans-serif;
    font-weight: 200;
    //color: white;
    margin: 0 2px;
  }

  .splitter {
    padding: 0 5px;
    color: black;
    font-size: 24px;
  }
}

.loading-shading-mui {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: rgba(255, 255, 255, .3);
}

.loading-icon-mui {
  position: absolute;
  font-size: 20px;
  top: calc(45% - 10px);
  left: calc(50% - 10px);
}

.dt-bg:read-only {
  background-color: #ffffff;
  cursor: pointer;
}

.input-error {
  border-color: red;
}

.form-label {
  font-weight: 700;
}

.required:after {
  content: " *";
  font-weight: 700;
  color: red;
}

.center-parent {
  text-align: center;
}

.white-wrap {
  white-space: pre-wrap;
}

.image-container {
  position: relative;
  min-height: 1px;
  height: auto !important;
  max-width: 204px;
  margin: auto;
  border-radius: 50%;
  border: 2px solid #dbdbdb;
  padding: 2px;
  cursor: pointer;
}

.rounded {
  border-radius: 50%;
}

.round-container {
  position: relative;
  text-align: center;
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
}

.round-container img {
  width: 200px;
  height: 200px;
  object-fit: contain;
  display: block
}

.skeleton-avatar {
  width: 200px;
  height: 200px;
}

@media screen and (max-width: 1200px) {
  .image-container {
    max-width: 154px;
  }
  .round-container img {
    width: 150px;
    height: 150px;
    object-fit: contain;
    display: block
  }
  .skeleton-avatar {
    width: 150px;
    height: 150px;
  }
}

.upload-button-container {
  transition: opacity .2s ease-in-out;
  background-color: rgba(32, 33, 36, 0.6);
  bottom: 0;
  height: 25%;
  left: 0;
  position: absolute;
  right: 0;
  opacity: 0;
}

.upload-button-icon-container {
  background: url(//www.gstatic.com/images/icons/material/system/2x/photo_camera_white_24dp.png) no-repeat center;
  background-size: 24px 24px;
  height: 100%;
  opacity: .8;
}

.round-container:hover > .upload-button-container {
  opacity: 1;
}

.ReactModalPortal {
  position: relative;
  z-index: 1000;
}

.backdrop-2 {
  z-index: 1300;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0
}

.swal2-container-custom {
  z-index: 1301!important;
}

.swal2-image-custom {
  max-width: 600px;
  max-height: 600px;
  object-fit: contain;
}

.document-detail-enter {
  opacity: 0;
  transform: translateY(-50%);
}
.document-detail-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.document-detail-exit {
  opacity: 1;
  transform: translateY(0);
}
.document-detail-exit-active {
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 300ms, transform 300ms;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-container > div {
  /*
    1/3  - 3 columns per row
    10px - spacing between columns
  */
  box-sizing: border-box;
  margin: 10px 10px 0 0;
  width: calc(1/2*100% - (1 - 1/2)*10px);
}

// List
.list-hover:hover {
  cursor: pointer;
  background-color: #EDF1F2;
}

.list-header {
  background-color: #F0F0F0;
  font-weight: bold;
  line-height: 32px;
  min-height: 32px;
}

.list-detail {
  line-height: 32px;
  min-height: 32px;
}

.list-docs {
  line-height: 52px;
  min-height: 52px;
}

.grid-nodata {
  padding: 48px 0;
  position: static !important;
  display: table-cell;
  //padding: 16px;
  font-size: 0.875rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}

.grid-nodata--text-container {
  left: 50%;
  display: inline-block;
  position: sticky;
  big {
    display: inline-block;
    transform: translateX(-50%);
  }
}